export const color = {
  primary1: '#7863A4',
  primary2: '#524287',
  primary3: '#41346C',
  black: '#000000',
  white: '#FFFFFF',
  gray1: '#3F3C3D',
  gray2: '#8A8A8A',
  gray3: '#DCDCDC',
  gray4: '#F6F6F6',
  gray5: '#FCFAF8',
  secondary1: '#4FC3C6',
  secondary2: '#F26E60',
  secondary3: '#EF5E8C',
  secondary4: '#27A6DE',
  secondary5: '#232757',
  secondary6: '#9ab540',
  secondary7: '#4cd1d8',
  transparent: 'transparent'
}

export const fontSize = {
  xxxl: '65px',
  xxl: '60px',
  xl: '40px',
  l: '28px',
  m: '22px',
  x: '20px',
  xs: '16px',
  xxs: '14px'
}

export const fontWeight = {
  extraBold: 800,
  bold: 700,
  semiBold: 600,
  normal: 400
}
