const React = require('react')
const { ThemeProvider } = require('emotion-theming')
const theme = require('./src/theme/variables')

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import('intersection-observer').then(() => {
      console.log('# IntersectionObserver is polyfilled!')
    })
  }
}

export const wrapRootElement = ({ element }) => {
  return <ThemeProvider theme={theme}>{element}</ThemeProvider>
}
